import { Component, OnInit } from '@angular/core';
import { MainTestService } from 'src/app/services/mainTest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mac-reg',
  templateUrl: './mac-reg.component.html',
  styleUrls: ['./mac-reg.component.scss']
})
export class MacRegComponent implements OnInit {

  oblIdList: any;
  oblId: number = 0;
  buildingId: number = 0;
  buildingList: any;
  roomList: any;
  roomId: number = 0;
  password: number;
  placeNum: number;
  date = sessionStorage.getItem('date');

  constructor(private mainTest: MainTestService, public translateService: TranslateService, private route: ActivatedRoute, private router: Router,) { }

  ngOnInit() {
    if(sessionStorage.getItem('obl') == '0'){
      this.getObl();
    }else{
      this.getObl();
      this.oblId = +sessionStorage.getItem('obl');
      this.getBuilding();
      this.buildingId = +sessionStorage.getItem('building');
      this.getRoomList();
      this.roomId = +sessionStorage.getItem('room');
      this.placeNum = +sessionStorage.getItem('placeNum');
    }
  }

  getObl(){
    this.mainTest.getRegObl()
    .subscribe((res: any) => {
      this.oblIdList = res.items;
    }, (err: any) => {

    });
  }

  getBuilding(){
    this.buildingId = 0;
    this.roomId = 0;
    this.mainTest.getBuilding(this.oblId)
      .subscribe((res: any) => {
        this.buildingList = res.items;
      }, (err: any) => {

      });
  }

  getRoomList(){
    this.roomId = 0;
    this.mainTest.getRooms(this.oblId, this.buildingId)
      .subscribe((res: any) => {
        this.roomList = res.items;
      }, (err: any) => {

      });
  }

  reg(){
    let data = {
      buildingId: this.buildingId,
      roomId: this.roomId,
      placeNum: this.placeNum,
      uniqueCode: sessionStorage.getItem('date'),
      password: this.password
    }
    this.mainTest.regPc(data)
      .subscribe((res: any) => {
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }, (err: any) => {

      });
  }

}
