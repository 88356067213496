import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  authorizeToken(token: string): boolean {
    sessionStorage.setItem('token', token);
    return true;
  }

  getHeaderAuth(): any {
    if(sessionStorage.getItem('token')){
      let headers = {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          'Unique-Code': '' + sessionStorage.getItem('date')
        }
      }
      return headers;
    }
    return this.getHeaderNotAuth();
  }

  getHeaderAuthSecond(): any {
    if(sessionStorage.getItem('token')){
      let headers = {
        headers: {
          'Unique-Code': '' + sessionStorage.getItem('date')
        }
      }
      return headers;
    }
    return this.getHeaderNotAuth();
  }

  aeroAuth(): any {
    let headers = {
      headers: {
        'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJvYm90LW50YyIsImZ1bGxuYW1lIjoiUm9ib3QgTlRDIiwicm9sZSI6InJvYm90IiwiaWF0IjoxNjE4NTc2NzA5LCJleHAiOjE2MjYzNTI3MDksImlzcyI6ImFlcm8tZW50In0.XM7wxbC2qNocZ261SXKFE_J8fGEcCxv2gCS66DYWbeE'
      }
    }
    return headers;
  }

  getHeaderNotAuth(): any {
    return {};
  }
}
