// tslint:disable: max-line-length
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TokenService } from "./token.service";
import { apiConfig } from "../config/apiRoutes";
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: "root"
})

export class MainTestService {

  getHtml(uri: string): any {
    return this.http.get(uri, {responseType: 'text'});
  }

  checkMachine(code: any){
    return this.http.get(this.config.backendChecker + "/computer/items/by-unique-code/" + code, this.tokenService.getHeaderAuth());
  }

  getRegObl(){
    return this.http.get(this.config.backendChecker + "/obl/items", this.tokenService.getHeaderAuth());
  }

  getBuilding(oblId: any){
    return this.http.get(this.config.backendChecker + "/obl/items/" + oblId + "/building/items", this.tokenService.getHeaderAuth());
  }

  getRooms(oblId: any, buildingId: any){
    return this.http.get(this.config.backendChecker + "/obl/items/" + oblId + "/building/items/" + buildingId + "/room/items", this.tokenService.getHeaderAuth());
  }

  regPc(data: any){
    return this.http.post(this.config.backendChecker + "/computer/items", data, this.tokenService.getHeaderAuth());
  }

  answersItems(subjectAnswer: any) {
    throw new Error("Method not implemented.");
  }
  constructor(private http: HttpClient, private tokenService: TokenService,
    private config: apiConfig, private sanitizer: DomSanitizer) {}
  // Получение списка тестовых заданий предмета тестирования
  questionItems(item: string): any {
    return this.http.get(this.config.apiRoutes.listOfItems + "/" + item + "/question/items", this.tokenService.getHeaderAuth());
  }

  startBiometry(iin: number){
    return this.http.post(this.config.backend + '/proctoring/rest/api/auth/exams/' + iin + '/authenticate', {}, this.tokenService.aeroAuth());
    // return this.http.post(this.config.backend + '/proctoring/rest/api/auth/exams/' + iin + '/authenticate', {}, this.tokenService.aeroAuth());
    // return this.http.post('http://192.168.200.16/proctoring/rest/api/auth/exams/' + iin + '/authenticate', {}, this.tokenService.aeroAuth());
    //  return this.http.post('https://proctoring.debug.testcenter.kz/rest/api/auth/exams/' + iin + '/authenticate', {}, this.tokenService.aeroAuth());
  }

  skipBiometry(iin: number){
    return this.http.post('http://3.127.247.26:3007/exams/' + iin + '/permitEntrance', {}, this.tokenService.aeroAuth());
  }

  startPause(duration: number){
    return this.http.post(this.config.apiRoutes.startPause, {duration: duration}, this.tokenService.getHeaderAuth());
  }

  goToEditing(){
    return this.http.post(this.config.backend + '/test/goto-editing', {}, this.tokenService.getHeaderAuth());
  }

  subTestInfo(prefix: any){
    return this.http.get(this.config.backendCustom + '/' + prefix + '/test-info', this.tokenService.getHeaderAuth());
  }

  finishPause(){
    return this.http.post(this.config.apiRoutes.finishPause, {}, this.tokenService.getHeaderAuth());
  }

  // Получение параметров тестового задания
  questionNum(questionId: number, predmetId: number): any {
    return this.http.get(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId, this.tokenService.getHeaderAuth());
  }
  // Начало апелляции по содержанию задания
  startAppeal(data: any): any {
    return this.http.post(this.config.apiRoutes.contentAppeal, data, this.tokenService.getHeaderAuth());
  }
  // Конец апелляции по содержанию задания
  endAppeal(data: any): any {
    return this.http.post(this.config.apiRoutes.contentAppealFinish, data, this.tokenService.getHeaderAuth());
  }
  // Получение списка причин аппеляции по тех. причинам
  techAppelItems(questionId: number, predmetId: number): any {
    return this.http.get(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/tech-appeal/reason/items", this.tokenService.getHeaderAuth());
  }
  // Получение списка причин аппеляции по содержанию задания
  contentAppelItems(questionId: number, predmetId: number): any {
    return this.http.get(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/content-appeal/reason/items", this.tokenService.getHeaderAuth());
  }
  // Получение данных заявления на апелляцию по техническим причинам
  techAppelContent(questionId: number, predmetId: number): any {
    return this.http.get(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/tech-appeal", this.tokenService.getHeaderAuth());
  }
  // Получение данных заявления на апелляцию по содержанию тестовых заданий
  contentAppelContent(questionId: number, predmetId: number): any {
    return this.http.get(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/content-appeal", this.tokenService.getHeaderAuth());
  }
  // Получение данных заявления на апелляцию по содержанию тестовых заданий
  contentAppelFileData(questionId: number, predmetId: number, id: number): any {
    return this.http.get(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/content-appeal/additional-file/items/" + id, this.tokenService.getHeaderAuth());
  }
  // Получение данных для карты ответов
  questionAnsweredItems(predmetId: number): any {
    return this.http.get(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items", this.tokenService.getHeaderAuth());
  }
  // Получение оставшегося времени тестирования
  timeLeft(): any {
    return this.http.get(this.config.apiRoutes.timeLeft, this.tokenService.getHeaderAuth());
  }
  // Завершение тестирования
  finishTest(data: any, finishTypeId: any): any {
    return this.http.post(this.config.apiRoutes.finishTest + '?finish-type-id=' + finishTypeId, data, this.tokenService.getHeaderAuth());
  }
  // Добавление заявления на апелляцию по тех. причине
  postAppeal(questionId: number, predmetId: number, data: any): any {
    return this.http.post(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/tech-appeal", data, this.tokenService.getHeaderAuth());
  }
  // Добавление заявления на апелляцию по содержанию задания
  postContentAppeal(questionId: number, predmetId: number, data: any): any {
    return this.http.post(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/content-appeal", data, this.tokenService.getHeaderAuth());
  }
  // Изменение данных заявления на апелляцию по тех. причине
  putAppeal(questionId: number, predmetId: number, data: any): any {
    return this.http.put(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/tech-appeal", data, this.tokenService.getHeaderAuth());
  }
  // Изменение данных заявления на апелляцию по содержанию задания
  putContentAppeal(questionId: number, predmetId: number, data: any): any {
    return this.http.put(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/content-appeal", data, this.tokenService.getHeaderAuth());
  }
  // Удаление заявления на апелляцию по тех. причине
  deleteAppeal(questionId: number, predmetId: number): any {
    return this.http.delete(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/tech-appeal", this.tokenService.getHeaderAuth());
  }
  // Удаление заявления на апелляцию по содержанию задания
  deleteContentAppeal(questionId: number, predmetId: number): any {
    return this.http.delete(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/content-appeal", this.tokenService.getHeaderAuth());
  }
  // Сохранение выбора варианта ответа
  selectedAnswer(predmetId: number, questionId: number,  answerOrder: number, form: any): any {
    return this.http.put(this.config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/answer/items/" + answerOrder, form, this.tokenService.getHeaderAuth());

  }
  // Получение списка предметов тестирования
  listOfItems(): any {
    return this.http.get(this.config.apiRoutes.listOfItems, this.tokenService.getHeaderAuth());
  }
  // Получение данных тестируемого
  studentInfo(): any {
    return this.http.get(this.config.backend + '/student-info', this.tokenService.getHeaderAuth());
  }
  // Авторизация
  auth(data: any): any {
    return this.http.post(this.config.apiRoutes.auth, data, this.tokenService.getHeaderAuth());
  }
  // Авторизация с биометрией
  authBio(testTypeId: any, iin: any): any {
    return this.http.get(this.config.backend + '/login/student-test-data/test-type/' + testTypeId + '/iin/' + iin, this.tokenService.getHeaderAuth());
  }
  // Получение параметров тестирования
  testInfo(): any {
    return this.http.get(this.config.apiRoutes.testInfo, this.tokenService.getHeaderAuth());
  }
  // Получение типов тестирования
  getAll(): any {
    return this.http.get(this.config.apiRoutes.testList, this.tokenService.getHeaderAuth());
  }
  // Получение правил тестирования 
  getRules(): any {
    return this.http.get(this.config.apiRoutes.rules, this.tokenService.getHeaderAuth());
  }
  // Согласие с правилами тестирования
  agreeRules(data: any): any {
    return this.http.post(this.config.apiRoutes.rules, data, this.tokenService.getHeaderAuth());
  }
  // Начало тестирования
  startTest(data: any): any {
    return this.http.post(this.config.apiRoutes.startTest, data, this.tokenService.getHeaderAuth());
  }

  testResultsImage(data: any): any {
    return this.http.post(this.config.apiRoutes.testResults, data, this.tokenService.getHeaderAuth());
  }

  getEntryPoint(prefix: any){
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/entry-point/items', this.tokenService.getHeaderAuth());
  }

}
