import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-biometry',
  templateUrl: './biometry.component.html',
  styleUrls: ['./biometry.component.scss']
})
export class BiometryComponent implements OnInit {

  constructor(public sanitizer: DomSanitizer,) { }

  ngOnInit() {

    


  }

}
