import { Component, OnInit, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainTestService } from 'src/app/services/mainTest.service';
import { TokenService } from 'src/app/services/token.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';




@Component({
  selector: 'app-auth',
  templateUrl: './hiddenAuth.component.html',
  styleUrls: ['./hiddenAuth.component.css'],
  providers: [MainTestService]
})
export class HiddenAuthComponent implements OnInit {
  typesTest: any = null;
  loading = true;
  CodeKT: any;
  typeId: any;
  pswrd: any;
  form: FormGroup;
  url:any;

  constructor(private mainTest: MainTestService,
              private tokenService: TokenService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.CodeKT = this.route.snapshot.queryParamMap.get('CodeKT');
    this.pswrd = this.route.snapshot.queryParamMap.get('pswrd');
    this.typeId = this.route.snapshot.queryParamMap.get('testTypeId');
    this.url = this.route.snapshot.queryParamMap.get('url');
    // this.getAllTestTypes();
    this.createForm();
    this.submitForm();

  }

  // Создание формы
  private createForm(): void {
    this.form = new FormGroup({
      // testTypeId: new FormControl(this.typeId, [Validators.required]),
      username: new FormControl(this.CodeKT, [Validators.required]),
      password: new FormControl(this.pswrd, [Validators.required])
    });
  }
  // get testTypeId() { return this.form.get('testTypeId');}
  get studentId() { return this.form.get('username');}
  get password() { return this.form.get('password');}


  // Авторизация
  private submitForm(): void {
    if(this.form.valid) {
      let obj = this.form.value;
      this.loading = true;
      this.mainTest.auth(obj)
          .subscribe((res: any) => {
            sessionStorage.setItem('testTypePrefix', res.data.specificDataServiceAddressPrefix);
            sessionStorage.setItem('testTypeGroupId', res.data.testTypeGroupId);
            if(this.tokenService.authorizeToken(res.data.token)) {
              sessionStorage.setItem("url", this.url);
              this.router.navigate(['/agreement']);
            }
          }, (err: any) => {
            switch (err.error.errorCode) {
              case 1:
                this.loading = false;
                alert(err.error.errorMessage.ru);
                break;

              default:
                break;
            }
            console.log(err);
          });
    }
  }


  // Получаем список видов тестирования
  private getAllTestTypes(): void {
    this.mainTest.getAll()
      .subscribe((res: any) => {
        if(res.items){
          this.typesTest = res.items;
        }
      }, (err: any) => {
        console.log(err);
      });
  }

  // Обработка ошибки
  private showErrors(): void {
    let showErrors = false;
  }



}

