import { Component, OnInit, isDevMode } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainTestService } from 'src/app/services/mainTest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
declare var $: any;
declare var html2canvas: any;

@Component({
  selector: 'app-content-appeal',
  templateUrl: './content-appeal.component.html',
  styleUrls: ['./content-appeal.component.css']
})
export class ContentAppealComponent implements OnInit {
  studentInfo: any = null; // Получение информации о сдающем тест
  listOfItems: any = null; // Cписок предметов
  timeLeft: number; // Оставшееся время тестирования
  finishTest: any = null; //
  audioValue = false; // Наличие аудиофайла
  audioUrl: any;
  appealMotivate: FormGroup; // Форма аппеляции по техническим причинам
  appealEditMotivate: FormGroup; // Форма редактирования аппеляции по техническим причинам
  appealEnd: FormGroup; // Форма завершения тестирования
  appealContent: FormGroup; // Форма аппеляции по содержанию задания
  addAppealForm: FormGroup;
  editAppealForm: FormGroup;
  currentTab: number; // Текущий вопрос
  questionItems = []; // Вопросы предмета
  currentPredmetId: number; // Текущий предмет
  predmetArray = []; // Массив списка предметов
  iFrameRoutePrefix: any;
  question: any; // Вопрос с 1 вариантом ответа
  contextQuestion: any; // Вопрос с несколькими вариантами ответов
  testEnding = false; // Модальное окно с фомой завершения тестирования
  appealStatus: any; // Статус аппляции вопроса
  nanoCaptchaIsValid = false; // Появление кнопки завершить тестирование после решения примера
  capthcaAnswer: any; // Ответ на пример
  iFrameQuest = []; // Варианты ответов
  checkStatusArr = []; // Проверка выбранного варианта ответа
  answerLetter = []; // Массив букв вариантов ответов
  appealItems = []; // Список причин аппеляции по технической причине
  contentAppealItems = []; // Список причин аппеляции по содержанию задания
  answerType: number;
  toggleMenu = false;
  items = [];
  order = [];
  predmetItems = [];
  overlayCheck = false;
  additionalFilesItems: any;
  showAppealModal = false;
  showEditAppealModal = false;
  showMapModal = false;
  showContentModal = false;
  dataImgScreen = '';
  inputCheck: any;
  testsInfo: any;
  canAppealCheck: boolean;
  lastQestion: any;
  submitted = false;
  editSubmitted = false;
  appealReason: any;
  appealDescription: any;
  editContent = null;
  loading: any;
  currentTime: string = ''; // Оставшееся время тестирования
  currentEditStatus: any;
  isTimeLimitedCheck: boolean;
  fileId: any;
  buttonText: any;
  descriptionCheck = false;
  fileNameCheck: any;
  editFileCheck = false;
  reasonCheck = false;
  fileDeleteModal = false;
  editFileDeleteModal = false;
  deleteModal = false;
  serverMessage: any;
  errWindow = false;
  soderjanieVoprosa: any;
  contextValue = false;
  loadingText = false;
  loadingContext = false;
  loadingAnswers = false;
  statusCount = 0;
  mainTimer: any;
  ping: any;

  editTxt = [
    {
      text: 'Разрешить редактирование'
    },
    {
      text: 'Запретить редактирование'
    }
  ];

  captchaQuest = [
   {
      quest: '2 + 2',
      answer: 4
   },
   {
    quest: '1 + 2',
    answer: 3
   },
   {
    quest: '3 + 2',
    answer: 5
   }
  ];
  fileIdToDelete: number;

  constructor(private router: Router,
              private route: ActivatedRoute, public sanitizer: DomSanitizer, 
              public translateService: TranslateService, private _notifications: NotificationsService,
              private mainTest: MainTestService,
              private http: HttpClient,
              public translate: TranslateService) { 
                if (isDevMode()) {
                  this.iFrameRoutePrefix = 'https://test.debug.testcenter.kz/'; // Путь для localhost
                }
                if (!isDevMode()) {
                  this.iFrameRoutePrefix = '/'; // Путь для боевого
                }
              }

  // Модальное окно с аппеляцией
  appealModal(): void {
    this.dataImgScreen = '';
    this.showAppealModal = true;
    let that = this;
    // var offsetHeight = document.getElementById('preview-block').offsetHeight + 130;
    var offsetHeight = document.getElementById('currentQuest').offsetHeight + 130;

    html2canvas($('#currentQuest')[0], {
      useCORS: true,
      height: offsetHeight,
      x: 310,
      y: 100
    }).then(function (canvas) {
      var canvasImg = canvas.toDataURL("image/jpg");
      that.dataImgScreen = canvasImg;
      that.data.setValue(canvasImg.substring(22));
    });
  }
  // Окно с подтверждением завершения тестирования
  endModal(): void {
    this.testEnding = true;

  }
  finishingTest(){
    let message1: string;
    this.translate.currentLang == 'ru' ? message1 = 'Решите простой пример: ' + this.capthcaAnswer.quest : message1 = 'Қарапайым мысалды шешіңіз: ' + this.capthcaAnswer.quest;
    let testEndQuest = prompt(message1, '0');
    if(testEndQuest == this.capthcaAnswer.answer){
      this.testEnd();
    } 
    if(testEndQuest != this.capthcaAnswer.answer && testEndQuest != null){
      this.translate.currentLang == 'ru' ? alert('Введен неверный ответ!') : alert('Қате жауап енгізілді!');
    }
  }
  // Модальное окно с апелляцией по содержанию тестового задания
  contentAppealModal(): void {
    this.showContentModal = true;
    this.createAddAppealForm();


  }
  // Модальное окно редактирования аппеляции
  appealEditModal(): void {
    this.showEditAppealModal = true;
    this.setAppelData();
  }

  nanoCaptchaHandle():void {
    if(this.appealEnd.get('nanoCaptcha').value == this.capthcaAnswer.answer){
      this.nanoCaptchaIsValid = true;
    }else{
      this.nanoCaptchaIsValid = false;
    }
  }

  private createAddAppealForm(): void {
    this.addAppealForm = new FormGroup({
      reasonTypeId: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      additionalFiles: new FormArray([])
    });
  }
  get additionalFiles(): any { return this.addAppealForm.get('additionalFiles');}
  get reasonType(): AbstractControl { return this.addAppealForm.get('reasonTypeId'); }
  get description(): AbstractControl { return this.addAppealForm.get('description'); }

  private createEditAppealForm(): void {
    this.editAppealForm = new FormGroup({
      reasonTypeId: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      additionalFiles: new FormArray([])
    });
  }
  get editReasonType(): AbstractControl { return this.editAppealForm.get('reasonTypeId'); }
  get editDescription(): AbstractControl { return this.editAppealForm.get('description'); }
  get editAdditionalFiles() : any { return this.editAppealForm.get('additionalFiles');}

  ngOnInit() {

    sessionStorage.setItem('showlang', '1');


    this.ping = setInterval(() =>{
      // console.log(ping);
      this.mainTest.timeLeft()
        .subscribe((res: any) => {
          this.timeLeft = +res.data.timeLeft;
          this.statusCount = 0;
        },(err: any) => {
          // console.log(err.ok);
          if(this.statusCount < 4){
            this.statusCount = this.statusCount + 1;
          } else{
            clearInterval(this.ping);
            alert('Потеряно соединение с сервером');
            this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
          }
      });  

      // console.log(this.statusCount);
      
    }, 60000);

    this.mainTest.testInfo()
      .subscribe((res: any) => {
        this.testsInfo = res.data;
        if(this.testsInfo.studentTestStatusTypeId == 2){
          this.router.navigate(['/test-rules']);
        }else if(this.testsInfo.studentTestStatusTypeId == 4){
          this.router.navigate(['/test/quest/' + this.currentPredmetId + '/' + this.currentTab]);
        }else if(this.testsInfo.studentTestStatusTypeId == 3){
          this.router.navigate(['/test/quest/' + this.currentPredmetId + '/' + this.currentTab]);
        }else if(this.testsInfo.studentTestStatusTypeId == 0){
          this.router.navigate(['/agreement']);
        }else if(this.testsInfo.studentTestStatusTypeId == 1){
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        }else if(this.testsInfo.studentTestStatusTypeId == 5){
          this.router.navigate(['/answer-map']);
        }else if(this.testsInfo.studentTestStatusTypeId == 8){
          this.router.navigate(['/answer-map']);
        }else if(this.testsInfo.studentTestStatusTypeId == 9){
          this.router.navigate(['/answer-map']);
        }else if(this.testsInfo.studentTestStatusTypeId == 7){
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        }else if(this.testsInfo.studentTestStatusTypeId == 6){
          this.router.navigate(['/content-appeal/quest/' + this.currentPredmetId + '/' + this.currentTab]);
        }
        this.canAppealCheck = res.data.testType.canAppeal;
      },(err: any) => {
        this.loading = false;
      });
    this.loadMapModal();


    this.capthcaAnswer = this.captchaQuest[Math.floor(Math.random() * this.captchaQuest.length)];
    this.buttonText = this.editTxt[0];

    this.appealContent = new FormGroup({
      reasonTypeId: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      additionalFiles: new FormGroup({
        id: new FormControl(''),
        description: new FormControl(''),
        filename: new FormControl('appealScreenShot-' + this.currentTab + '-' + this.currentPredmetId + '.jpg'),
        data: new FormControl(this.dataImgScreen, [Validators.required])
      })
    });
    this.appealEnd = new FormGroup({
      nanoCaptcha: new FormControl('')
    })

    this.createEditAppealForm();
    this.mainTest.testInfo()
      .subscribe((res: any) => {
        this.testsInfo = res.data;
        this.canAppealCheck = res.data.testType.canAppeal;
      },(err: any) => {
        this.loading = false;
        console.log(err.error.errorMessage.ru);
        alert(err.error.errorMessage.ru);
      });

    this.items = [];
    // this.getPredmets();

    // Получение списка предметов
    this.mainTest.listOfItems()
    .subscribe((res: any) => {
      this.listOfItems = res.data;
      this.predmetItems = res.data.subjects[0];
        for (let index = 0; index < this.listOfItems.subjects.length; index++) {
          const element = this.listOfItems.subjects[index];
          let subjectOrder = element['order'];
          if(element.showInAppeal == true){
            this.predmetArray.push(subjectOrder);
          }
        }
    },(err: any) => {
      this.loading = false;
      console.log(err.error.errorMessage.ru);
      alert(err.error.errorMessage.ru);
    });

    // Получение информации о сдающем тест
    let prefix = sessionStorage.getItem('testTypePrefix');
       this.mainTest.studentInfo()
      .subscribe((res: any) => {
        this.studentInfo = res.data;
    },(err: any) => {
      this.loading = false;
      console.log(err.error.errorMessage.ru);
      alert(err.error.errorMessage.ru);
    });

    // Время для таймера
    this.mainTest.timeLeft()
    .subscribe((res: any) => {
      this.timeLeft = +res.data.timeLeft;
      this.isTimeLimitedCheck = res.data.isTimeLimited;
      if(this.isTimeLimitedCheck){
         this.timer();
      }
    }, (err: any) => {
      this.loading = false;
      if(err.status == 401){
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }else{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
      }
    });

    this.route.params.subscribe(paramsId => {
      this.currentPredmetId = paramsId.predId;
      this.currentTab = paramsId.questId;
      if(this.questionItems.length === 0){
        this.stepHandler(this.currentPredmetId);
      }
      
      this.appealMotivate = new FormGroup({
        reasonTypeId: new FormControl(''),
        description: new FormControl(''),
        testPageImage: new FormGroup({
          filename: new FormControl('appealScreenShot-' + this.currentTab + '-' + this.currentPredmetId + '.jpg'),
          data: new FormControl(this.dataImgScreen)
        })
      });
      
      // Получение вопроса и вариантов ответа с выбранным варинтом ответа
      this.mainTest.questionNum(this.currentTab, this.currentPredmetId)
          .subscribe((res: any) => {
            this.loadingText = true;
            this.loadingAnswers = true;
            this.loadingContext = true;
            this.iFrameQuest = [];
            this.contextQuestion = '';
            this.checkStatusArr = [];
            this.answerLetter = [];
            this.answerType = res.data.type;
            let iFrameRoute = this.iFrameRoutePrefix + res.data.url;
            if(res.data.contentAppealExists){
              this.appealStatus = true;
            }else{
              this.appealStatus = false;
            }

            // if(this.questionItems[this.currentTab - 1].isCanAppeal){
        
              if(res.data.isAdditionalContentExists && res.data.additionalContent.type === 1){
                // Контекстаное задание
                let iFrameContext = this.iFrameRoutePrefix + res.data.additionalContent.url;
                this.contextValue = true;

                this.contextQuestion = res.data.additionalContent.content; // Содержимое текстового контекстного задания // Содержимое текстового контекстного задания
  
                // this.mainTest.getHtml(iFrameContext)
                // .subscribe((res: any) => {
                //   // this.contextQuestion =  this.sanitizer.bypassSecurityTrustHtml(res);
                // }, (err: any) => {
                //   this.loading = false;
                //   console.log(err.error.errorMessage.ru);
                //   alert(err.error.errorMessage.ru);
                // });
              }else{
                this.contextValue = false;
              }
              if(res.data.isAdditionalContentExists && res.data.additionalContent.type === 1){
                // Контекстаное задание
                let iFrameContext = this.iFrameRoutePrefix + res.data.additionalContent.url;
                this.contextValue = true;
  
                this.contextQuestion = res.data.additionalContent.content; // Содержимое текстового контекстного задания
                this.loadingContext = false;
  
  
               
              }
              else{
                this.contextValue = false;
                this.loadingContext = false;
              }
              if(res.data.isAdditionalContentExists && res.data.additionalContent.type === 2){
                this.audioValue = true;
                this.audioUrl = this.iFrameRoutePrefix + res.data.additionalContent.url;
              }else{
                this.audioValue = false;
              }
  
              this.question = res.data.content; // Содержимое вопроса
              this.loadingText = false;
  
              // this.mainTest.getHtml(iFrameRoute)
              //   .subscribe((res: any) => {
              //     // this.question =  this.sanitizer.bypassSecurityTrustHtml(res);
              //   },(err: any) => {
              //     this.loading = false;
              //     console.log(err.error.errorMessage.ru);
              //     alert(err.error.errorMessage.ru);
              //   });
  
              // Вопрос
              this.mainLoadAnswer(res.data);
            // }
            
          },(err: any) => {
            this.loadingText = false;
            this.loadingAnswers = false;
            this.loadingContext = false;
          });
      // // Список причин для аппеляции по тех. причинам
      // this.mainTest.contentAppelItems(this.currentTab, this.currentPredmetId)
      //   .subscribe((res: any) => {
      //     this.appealItems = [];
      //     if(res.items){
      //       this.appealItems = res.items;
      //     }
      //   });
      // Список причин для аппеляции по содержанию задания
      this.mainTest.contentAppelItems(this.currentTab, this.currentPredmetId)
        .subscribe((res: any) => {
          this.contentAppealItems = [];
          if(res.items){
            this.contentAppealItems = res.items;
          }
        },(err: any) => {
          this.loading = false;
          console.log(err.error.errorMessage.ru);
          alert(err.error.errorMessage.ru);
        });
    });
  }

  get filename() { return this.appealMotivate.get('testPageImage').get('filename'); }
  get data() { return this.appealMotivate.get('testPageImage').get('data'); }

  async mainLoadAnswer(data): Promise<any> {

    for (let index = 0; index < data.answers.length; index++) {
      const element = data.answers[index];
      // this.loading = true;
      this.iFrameQuest.push(element.content); // Варианты ответов
      this.checkStatusArr.push(element.isSelected); // Выбранный вариант ответа
      this.answerLetter.push(element.letter); // Буква варианта ответа
      this.order.push(element.order);
      this.loadingAnswers = false;
    }
    
  }

  loadingContent(){
    return this.loadingContext || this.loadingText || this.loadingAnswers;
  }

  startLoading(itemNum: number){
    if(itemNum != this.currentTab){
      this.loadingText = true;
      this.loadingContext = true;
      this.loadingAnswers = true;
    }
  }

  loadAnswer(element): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainTest.getHtml(this.iFrameRoutePrefix + element.url)
      .subscribe((res: any) => {
        resolve(res);
      }, (err: any) => {
        this.loading = false;
        reject(err);
      });
    })

  }

  // private getPredmets(): void {
  //   // Получение списка предметов
  //   this.mainTest.listOfItems()
  //     .subscribe((res: any) => {
  //       res.data.subjects.forEach(element => {
  //         let obj = {
  //           element: element,
  //           answers: []
  //         }
  //         this.items.push(obj);
  //       });

  //       this.getAnswer();
  //     },(err: any) => {
  //       this.loading = false;
  //       console.log(err.error.errorMessage.ru);
  //       alert(err.error.errorMessage.ru);
  //     });
  //   }
  loadMapModal(): void {
    // Получение списка предметов
    this.mainTest.listOfItems()
      .subscribe((res: any) => {
        this.items = [];
        res.data.subjects.forEach(element => {
          if(element.showInCard == true){
          let obj = {
            element: element,
            answers: []
          }
          this.items.push(obj);
        }
        });

        this.getAnswer();
      },(err: any) => {
        this.loading = false;
        if(err.status == 401){
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });

  }

      private getAnswer(): void {
        this.items.forEach((element, i) => {
          delete this.items[i]['answers'];
          this.items[i].answers = [];
          this.loading = true;
          this.mainTest.questionAnsweredItems(element.element.order)
            .subscribe((res: any) => {
              this.items[i]['answers'].push(res.data.questions);
              this.loading = false;
            }, (err: any) => {
              this.loading = false;
              if(err.status == 401){
                this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
                this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
              }else{
                this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
              }
            });
        });
      }
  // Модальное окно с картой ответов
  mapModal(): void {
    this.showMapModal = true;
    this.getAnswer();
  }
  // Следующий предмет

  stepHandler(value: number): void {
    if(value != this.currentPredmetId){
      this.router.navigate(['/content-appeal/quest/' + (+value) + '/' + 1]);
      this.getPredmetQuestions(value); // Список вопросов
    }else{
      this.router.navigate(['/content-appeal/quest/' + (+value) + '/' + this.currentTab]);
      this.getPredmetQuestions(value); // Список вопросов
    }

  }
  // Получение информации о заявлении аппеляции
  getAppealStatus(): void {
    this.mainTest.contentAppelItems(this.currentTab, this.currentPredmetId)
    .subscribe((res: any) => {
      },(err: any) => {
        this.loading = false;
        console.log(err.error.errorMessage.ru);
        alert(err.error.errorMessage.ru);
      });
  }

  // Проверка того подана ли уже аппеляция
  checkAppealStatus(): void {
    this.mainTest.contentAppelContent(this.currentTab, this.currentPredmetId)
      .subscribe((res: any) => {
        if(res.data.reasonTypeId != null){
          this.appealEditMotivate = new FormGroup({
          });
        }
      },(err: any) => {
        this.loading = false;
        this.appealStatus = false;
        console.log(err.error.errorMessage.ru);
        alert(err.error.errorMessage.ru);
      });
  }
  // Редактировать аппеляцию по содержанию задания
  etidAppeal(){
    let data: any;
    data = this.editAppealForm.value;
    this.mainTest.putContentAppeal(this.currentTab, this.currentPredmetId, data)
      .subscribe((res: any) => {
        this.translateService.currentLang == 'ru' ? this._notifications.create('Заявление', 'успешно отредактированно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті өзгертілді', NotificationType.Success, 5000);
        this.showEditAppealModal = false;
        this.descriptionCheck = false;
        this.editFileCheck = false;
      },(err: any) => {
        this.loading = false;
        if(err.status == 401){
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
  }
  // Добавить еще 1 input для файла
  addFile(type: string) {
    let obj = null;
    if (type === 'add') {
      obj = this.additionalFiles;
    } else {
      obj = this.editAdditionalFiles;
    }
    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        data: new FormControl()
      })
    );
  }


  changeListener($event, i: number, type: string) : void {
    let obj = null;
    if (type === 'add') {
      obj = this.additionalFiles;
    } else {
      obj = this.editAdditionalFiles;
    }

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      obj.controls[i].get('description').setValue();
      let imgData = myReader.result;
      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

   // Модальное окно вопроса на удаление аппеляции
   appealDeleteModal(): void {
    this.deleteModal = true;
  }

  // Удаление input для файла
  removeFile(type: string){
    this.translateService.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    this.fileDeleteModal = false;
    this.editFileDeleteModal = false;
    this.overlayCheck = false;
    let obj = null;
    if(type == "add"){
      obj = this.additionalFiles;
    } else {
      obj = this.editAdditionalFiles;
    }
    obj.removeAt(this.fileIdToDelete);
  }
  // Кнопка редактирования input
  changeEditContent(index: number, filename: string) {
      this.editContent = (index == this.editContent ? null:index);
  }

downloadFile(id: number) {
  this.mainTest.contentAppelFileData(this.currentTab, this.currentPredmetId, +this.editAdditionalFiles.controls[id].get('id').value)
    .subscribe((res: any) => {
      saveAs(this.b64toBlob(res.data.data, '**/*'), res.data.filename);
    });
}

b64toBlob(b64Data, contentType='', sliceSize=512){
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


  // Удаление аппеляции по тех. причине
  appealDelete(){
    this.mainTest.deleteContentAppeal(this.currentTab, this.currentPredmetId)
      .subscribe((res: any) => {
        this.translateService.currentLang == 'ru' ? this._notifications.create('Заявление', 'успешно удалено', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті жойылды', NotificationType.Success, 5000);
        this.deleteModal = false;
        this.appealStatus = false;
        this.questionItems[this.currentTab - 1].isContentAppealExists = false;
      },(err: any) => {
        this.loading = false;
        console.log(err.error.errorMessage.ru);
        alert(err.error.errorMessage.ru);
      });
  }
  // Получение вопросов предмета
  private getPredmetQuestions(subjectOrder): void {
    this.mainTest.questionItems(subjectOrder)
    .subscribe((res: any) => {
      this.questionItems = [];
      for (let i = 0; i < res.data.questions.length; i++) {
        const el = res.data.questions[i];
        this.questionItems.push(el);
        this.lastQestion = res.data.questions.length;
      }
    },(err: any) => {
      this.loading = false;
      console.log(err.error.errorMessage.ru);
      alert(err.error.errorMessage.ru);
    });
  }
  // Следующий вопрос
  nextQuestion(): void {
    this.loadingAnswers = true;
    this.loadingContext = true;
    this.loadingText = true;
    this.router.navigate(['/content-appeal/quest/' + (+this.currentPredmetId) + '/' + (+this.currentTab+1)]);
  }
  // Предыдущий вопрос
  previousQuestion(): void {
    this.loadingAnswers = true;
    this.loadingContext = true;
    this.loadingText = true;
    this.router.navigate(['/content-appeal/quest/' + (+this.currentPredmetId) + '/' + (+this.currentTab-1)]);
  }

 // Завершение апелляции
  testEnd(): void {
    this.loading = true;
    let data: any;
    this.mainTest.endAppeal(data)
      .subscribe((res: any) =>{
        this.loading = false;
        clearInterval(this.mainTimer);
        clearInterval(this.ping);
        this.router.navigate(['/answer-map']);
      }, (err: any) => {
        this.loading = false;
      })
  }
  showSideMenu(): void {
    this.toggleMenu = true;
  }
  hideSideMenu(): void {
    this.toggleMenu = false;
  }

  postContentAppeal(): void {
    this.loading = true;
    let data: any;
    data = this.addAppealForm.value;
    this.mainTest.postContentAppeal(this.currentTab, this.currentPredmetId, data)
     .subscribe((res: any) => {
       if(res.errorCode == 0){
        this.translateService.currentLang == 'ru' ? this._notifications.create('Заявление', 'успешно подано', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті берілді', NotificationType.Success, 5000);
        this.showContentModal = false;
        this.descriptionCheck = false;
        this.editFileCheck = false;
        this.loading = false;
        this.reasonCheck = false;
        this.appealStatus = true;

        this.questionItems[this.currentTab - 1].isContentAppealExists = true;
       }
    },(err: any) => {
      this.loading = false;
      if(err.status == 401){
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }else{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
      }
    });
  }

  onSubmitAdd(){
    this.submitted = true;

    if (this.addAppealForm.invalid) {
      return;
    }

  }

  

  onSubmitEdit(){
    this.editSubmitted = true;

    if (this.editAppealForm.invalid) {
      return;
    }

  }

  setAppelData(){
    this.loading = true;
    this.editAdditionalFiles.clear();
    this.mainTest.contentAppelContent(this.currentTab, this.currentPredmetId)
      .subscribe((res: any) => {
          this.loading = false;

        this.editAppealForm.get('reasonTypeId').setValue(res.data.reasonTypeId);
        this.editAppealForm.get('description').setValue(res.data.description);
        res.data.additionalFiles.forEach(element => {
          this.inputCheck = element.filename;
          this.editAdditionalFiles.push(
            new FormGroup({
              id: new FormControl(element.id),
              filename: new FormControl(element.filename),
              description: new FormControl(element.description),
              data: new FormControl("")
            })
          )

        });
      },(err: any) => {
        this.loading = false;
        console.log(err.error.errorMessage.ru);
        alert(err.error.errorMessage.ru);
      });
  }

  fileDelete(i: number): void{
    this.fileIdToDelete = i;
    this.fileDeleteModal = true;
    this.overlayCheck = true;
  }
  editFileDelete(i: number): void{
    this.fileIdToDelete = i;
    this.editFileDeleteModal = true;
    this.overlayCheck = true;
  }
  closeDeleteModal(): void {
    this.editFileDeleteModal = false;
    this.fileDeleteModal = false;
    this.overlayCheck = false;
  }

  //Таймер
  timer(): void {
   this.mainTimer = setInterval(() => {
      if(this.timeLeft == 0) {
        // Конец таймера
        this.errWindow = true;
        // this.serverMessage = 'Время для подачи аппеляции закончилось закончилось';
        this.testEnd();
      }
      if(this.timeLeft == 1800){
        this.translateService.currentLang == 'ru' ? this._notifications.create('Осталось', '30 минут', NotificationType.Alert, 5000) : this._notifications.create('30', 'минут қалды', NotificationType.Success, 5000);
      } else if(this.timeLeft == 1200){
        this.translateService.currentLang == 'ru' ? this._notifications.create('Осталось', '20 минут', NotificationType.Alert, 5000) : this._notifications.create('20', 'минут қалды', NotificationType.Success, 5000);
      } else if(this.timeLeft == 600){
        this.translateService.currentLang == 'ru' ? this._notifications.create('Осталось', '10 минут', NotificationType.Alert, 5000) : this._notifications.create('10', 'минут қалды', NotificationType.Success, 5000);
      }
      const minute = 60;
      const hour = minute * 60;
      const day = hour * 24;
      let hours = this.timeLeft % day / hour;
      let minutes = this.timeLeft % hour / minute;
      let seconds = this.timeLeft % minute;
      if(seconds < 10 && minutes > 10){
        this.currentTime = ~~hours + ':' + ~~minutes + ':' + '0' + ~~seconds;
      }else if(minutes < 10 && seconds < 10){
        this.currentTime = ~~hours + ':' + '0' + ~~minutes + ':' + '0' + ~~seconds;
      }else if(minutes < 10){
        this.currentTime = ~~hours + ':'+ '0' + ~~minutes + ':' + ~~seconds;
      }else{
        this.currentTime = ~~hours + ':' + ~~minutes + ':' + ~~seconds;
      }
      this.timeLeft = this.timeLeft - 1;
    }, 1000);
  }

  dwnldCheck(id: number): boolean {
    return this.editAdditionalFiles.controls[id].get('filename').value !== '';
  }

  dwnldCheckAdd(id: number): boolean {
    return this.additionalFiles.controls[id].get('filename').value !== '';
  }

  dwnldCheckId(id: number): boolean {
    return +this.editAdditionalFiles.controls[id].get('id') != 0;
  }

  private getTestInfo(): void {
    this.mainTest.testInfo()
    .subscribe((res: any) => {
      this.testsInfo = res.data;
      if(this.testsInfo.studentTestStatusTypeId == 2){
        this.router.navigate(['/test-rules']);
      }else if(this.testsInfo.studentTestStatusTypeId == 4){
        this.router.navigate(['/test/quest/' + this.currentPredmetId + '/' + this.currentTab]);
      }else if(this.testsInfo.studentTestStatusTypeId == 3){
        this.router.navigate(['/test/quest/' + this.currentPredmetId + '/' + this.currentTab]);
      }else if(this.testsInfo.studentTestStatusTypeId == 0){
        this.router.navigate(['/agreement']);
      }else if(this.testsInfo.studentTestStatusTypeId == 1){
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }else if(this.testsInfo.studentTestStatusTypeId == 5){
        this.router.navigate(['/answer-map']);
      }else if(this.testsInfo.studentTestStatusTypeId == 8){
        this.router.navigate(['/answer-map']);
      }else if(this.testsInfo.studentTestStatusTypeId == 9){
        this.router.navigate(['/answer-map']);
      }else if(this.testsInfo.studentTestStatusTypeId == 7){
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }else if(this.testsInfo.studentTestStatusTypeId == 6){
        this.router.navigate(['/content-appeal/quest/' + this.currentPredmetId + '/' + this.currentTab]);
      }

    }, (err: any) => {
      this.loading = false;
      if(err.status == 401){
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }else{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
      }
    });
  }


  // Логика выбора ответов 1 варианта ответов
  answerSelect(i: number): void {

    this.checkStatusArr[i] = !this.checkStatusArr[i];
    let obj = {
      isSelected: this.checkStatusArr[i],
      selected: this.checkStatusArr[i]
    }

    this.mainTest.selectedAnswer(this.currentPredmetId, this.currentTab, i+1, obj)
      .subscribe((res: any) => {
        this.checkStatusArr.forEach((element, index) => {
          if(!res.data.selectedAnswers.includes(index+1)){
            this.checkStatusArr[index] = false;
          }
        });
      },(err: any) => {
        this.loading = false;
        console.log(err.error.errorMessage.ru);
        alert(err.error.errorMessage.ru);
      });
  }

}
