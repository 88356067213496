import { Component, OnInit } from '@angular/core';
import { MainTestService } from 'src/app/services/mainTest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AgreementService } from 'src/app/services/agreement.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';


@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css'],
})


export class AgreementComponent implements OnInit {

  loading = true; // Проверка загрузки
  testsInfo: any = { lang: { name: { ru: null, kz: null } }, testType: { name: { ru: null, kz: null } } }; // Массив с информацией о тесте
  studentInfo: any = { lastname: null, firstname: null, patronymic: null }; // Массив с информацией о тестируемом
  listOfItems: any = { subjets: null }; // Список предметов
  testTypeValue: any;
  educationLanguageId = 0;
  radioData: number;
  listOfLanguages = [];
  foreignLanguages = [];
  tgoLanguages = [];
  eduPrograms = [];
  showInfo = false;
  isSubmitted: any;
  showForeignLanguage = false;
  pvoTypeCheck = false;
  ktTypeCheck = false;
  registrationForm: FormGroup;
  studentInfoForm: FormGroup;
  socketEnter = false;
  subTestInfo: any;
  subjectPairList: any;
  collegeSpecList: any;
  varData = [];
  listOfEntryPoints: any;

  specList: any;

  testTypeGroupId: number;
  testPrefix: any;
  subjectsList: any;
  pSubjects: any;
  employmentTypes: any[] = [];

  constructor(
    public router: Router,
    private mainTest: MainTestService,
    private route: ActivatedRoute,
    private agreementService: AgreementService,
    public translateService: TranslateService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    public fb: FormBuilder
  ) { }

  ngOnInit() {
    sessionStorage.setItem('showlang', '1');

    sessionStorage.setItem('reportList', '');

    this.testTypeGroupId = +sessionStorage.getItem('testTypeGroupId');
    this.testPrefix = sessionStorage.getItem('testTypePrefix');

    this.registrationForm = this.fb.group({
      testTypeId: [''],
      entryPointId: [0],
      employmentTypeId: [0],
      eduLanguageId: [0],
      foreignLanguageId: [0],
      tgoLanguageId: [0],
      eduProgramId: [0],
      profileSubjectPairId: [0],
      testLanguageId: [0],
      subjectId: [0],
      specialityId: [0],
      eduTypeId: [0],
      collegeSpecId: [0],
      firstname: [''],
      lastname: [''],
      patronymic: [''],
      iin: ['']
    });
    this.studentInfoForm = this.fb.group({

    });

    this.loading = true; // Активация загрузочного экрана



    this.mainTest.studentInfo() // Получение информации о сдающем тест
      .subscribe((res: any) => {
        this.studentInfo = res.data;
        this.testInfo();
        this.registrationForm.get('firstname').setValue(this.studentInfo.firstname);
        this.registrationForm.get('iin').setValue(this.studentInfo.iin);


        this.registrationForm.get('lastname').setValue(this.studentInfo.lastname);
        this.registrationForm.get('patronymic').setValue(this.studentInfo.patronymic);

      }, (err: any) => {
        if (err.status == 401) {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });

    if (this.testTypeGroupId == 3) {
      this.registrationForm.get('testTypeId').setValue(0);
      this.mainTest
        .getEntryPoint(this.testPrefix) // Получение списка предметов
        .subscribe((res: any) => {
          this.listOfEntryPoints = res.items;
          // console.log(!!res.data.subjects[3].testLanguageName.kz);
        });
    }



    // this.mainTest
    //   .listOfItems() // Получение списка предметов
    //   .subscribe((res: any) => {
    //     this.listOfItems = res.data;
    //     // console.log(!!res.data.subjects[3].testLanguageName.kz);
    //   });

    this.loading = false; // После загрузки страницы отключение загрузочноог экрана

    // this.editDataUrl();

    if (this.testTypeGroupId == 4) {
      this.agreementService.getSubjectLangs(this.testPrefix, 39)
        .subscribe((res: any) => {
          this.listOfLanguages = res.items;
        }, (err: any) => {

        });
    }




  }

  returnPage(){
    let url = sessionStorage.getItem("url");
    console.log("Url ", url)
    if(url != undefined || url !=null){
      window.open(url, "_self");
    }else{
      this.router.navigateByUrl('/');
    }
  }

  varInfo() {
    this.agreementService.getTable(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('profileSubjectPairId').value, this.registrationForm.get('testLanguageId').value)
      .subscribe((res: any) => {
        this.varData = res.items;
      }, (err: any) => {

      });
  }

  varInfoNqt() {

    if (this.registrationForm.get('testTypeId').value == 40) {
      this.agreementService.getTableTipoNqt(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('employmentTypeId').value, this.registrationForm.get('eduTypeId').value, this.registrationForm.get('specialityId').value, this.registrationForm.get('subjectId').value, this.registrationForm.get('testLanguageId').value)
        .subscribe((res: any) => {
          this.varData = res.items;
        }, (err: any) => {

        });
    } else {
      this.agreementService.getTableNqt(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('employmentTypeId').value, this.registrationForm.get('subjectId').value, this.registrationForm.get('testLanguageId').value)
        .subscribe((res: any) => {
          this.varData = res.items;
        }, (err: any) => {

        });
    }
  }

  /*########### Template Driven Form ###########*/


  // Getter method to access form control
  get myForm() { return this.registrationForm.get('testTypeId'); }

  // Submit Registration Form
  testTypeChange(value: any, code: any) {
    this.listOfLanguages = [];
    this.registrationForm.get('testTypeId').setValue(value);
    if (code == 1) {
      this.registrationForm.get('eduLanguageId').setValue(0);
      this.registrationForm.get('foreignLanguageId').setValue(0);
      this.registrationForm.get('tgoLanguageId').setValue(0);
      this.registrationForm.get('eduProgramId').setValue(0);
      this.registrationForm.get('testLanguageId').setValue(0);
      this.registrationForm.get('profileSubjectPairId').setValue(0);
      this.registrationForm.get('employmentTypeId').setValue(0);

      this.registrationForm.get('collegeSpecId').setValue(0);

      if (value == 1 || value == 11 || value == 5 || value == 8 || value == 9 || value == 10 || value == 7 || value == 11) {
        // this.agreementService.getSubjectLang(this.testPrefix, this.registrationForm.get('testTypeId').value, 0)
        //   .subscribe((res: any) => {
        //     this.listOfLanguages = res.items;
        //   }, (err: any) => {

        //   });
      }
    }

    this.testTypeValue = value;


    if (value == 4) {
      this.showForeignLanguage = true;
      this.agreementService.getForeignLanguage(value, 0, this.testPrefix)
        .subscribe((res: any) => {
          this.foreignLanguages = res.items;
        });
    }
    if (value == 5) {
      if (this.educationLanguageId == 3) {
        this.showForeignLanguage = false;
      } else {
        this.showForeignLanguage = true;
      }
      this.agreementService.getForeignLanguage(value, this.educationLanguageId, this.testPrefix)
        .subscribe((res: any) => {
          this.foreignLanguages = res.items;
          // this.eduLangInit();
        });
    }

    // if(value == 33){
    //   this.agreementService.getSubjectPair(this.testPrefix, 33)
    //     .subscribe((res: any) => {
    //       this.subjectPairList = res.items;
    //     }, (err: any) => {

    //   });
    // }

    // if(value == 34){
    //   this.agreementService.getCollegeSpec(this.testPrefix, 34)
    //     .subscribe((res: any) => {
    //       this.collegeSpecList = res.items;
    //     }, (err: any) => {

    //   });
    // }

    this.isSubmitted = true;
    if (!this.registrationForm.valid) {
      return false;
    } else {
      if (this.testTypeGroupId == 1) {
        this.agreementService.eduLanguage(value, this.testPrefix)
          .subscribe((res: any) => {
            this.listOfLanguages = [];
            this.listOfLanguages = res.items;
          });
      }

    }

    if (this.testTypeGroupId == 3) {
      if (code == 1) {
        this.listOfEntryPoints.forEach((element: any) => {
          if (element.id == value) {
            this.registrationForm.get('entryPointId').patchValue(element.id);
            this.registrationForm.get('testTypeId').patchValue(element.testTypeId);
            this.subjectsList = [];
            this.varData = [];
            this.registrationForm.get('subjectId').setValue(0);
            this.agreementService.getEmploymentTypes(this.testPrefix, this.registrationForm.get('testTypeId').value).subscribe({
              next: (res: any) => {
                this.employmentTypes = res.items;
              }
            });


          }
        });
      } else {
        this.subjectsList = [];
        this.varData = [];
        this.registrationForm.get('subjectId').setValue(0);
        this.agreementService.getEmploymentTypes(this.testPrefix, this.registrationForm.get('testTypeId').value).subscribe({
          next: (res: any) => {
            this.employmentTypes = res.items;
          }
        });
      }


    }

  }

  emplTypesChange(value: number) {
    this.subjectsList = [];
    this.registrationForm.get('subjectId').setValue(0);
    this.registrationForm.get('testLanguageId').setValue(0);
    this.registrationForm.get('eduTypeId').setValue(0);
    this.registrationForm.get('specialityId').setValue(0);

    this.varData = [];
    if (this.registrationForm.get('testTypeId').value == 35) {
      this.agreementService.getSubjects(this.testPrefix, this.registrationForm.get('testTypeId').value, value)
        .subscribe((res: any) => {
          this.subjectsList = res.items;
        });
    } else if (this.registrationForm.get('testTypeId').value == 40) {
      this.agreementService.getPSubjects(this.testPrefix, this.registrationForm.get('testTypeId').value, value)
        .subscribe((res: any) => {
          this.pSubjects = res.items;
        }, (err: any) => {

        })
    }

    else {
      this.getSubjectLang(0);
    }


  }

  profileSubjectPairSelect() {
    this.registrationForm.get('testLanguageId').setValue(0);
    this.agreementService.getEntTestLang(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.listOfLanguages = res.items;
      }, (err: any) => {

      });
  }

  collegeSpecSelect() {
    this.registrationForm.get('eduProgramId').setValue(0);
    this.registrationForm.get('testLanguageId').setValue(0);
    this.agreementService.getEntCollegeEduProgram(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('collegeSpecId').value)
      .subscribe((res: any) => {
        this.eduPrograms = res.items;
      }, (err: any) => {

      });
  }

  eduProgramSelect() {
    this.registrationForm.get('testLanguageId').setValue(0);
    this.agreementService.getEntCollegeLangList(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('eduProgramId').value)
      .subscribe((res: any) => {
        this.listOfLanguages = res.items;
      }, (err: any) => {

      });
  }

  testInfo() {
    this.loading = true;
    this.mainTest
      .testInfo() // Получение информации о тестировании
      .subscribe((res: any) => {
        this.loading = false;

        this.mainTest.subTestInfo(this.testPrefix)
          .subscribe((res: any) => {
            this.subTestInfo = res.data;

            if (res.data.studentTestStatusTypeId != 1) {
              if (this.testTypeGroupId == 1) {
                this.registrationForm.get('testTypeId').setValue(res.data.testType.id);
                this.registrationForm.get('eduLanguageId').setValue(res.data.eduLanguage.id);
                this.registrationForm.get('foreignLanguageId').patchValue(res.data.foreignLanguage.id);
                this.registrationForm.get('tgoLanguageId').setValue(res.data.tgoLanguage.id);
                this.registrationForm.get('eduProgramId').setValue(res.data.eduProgram.id);

                if (this.registrationForm.get('testTypeId').value == 5) {
                  this.ktTypeCheck = true;
                  this.testTypeChange(5, 0);
                  this.educationLanguageSelect(res.data.eduLanguage.id);
                }
                if (this.registrationForm.get('testTypeId').value == 4) {
                  this.pvoTypeCheck = true;
                  this.testTypeChange(4, 0);
                }
              }

              if (this.testTypeGroupId == 2) {
                this.registrationForm.get('testTypeId').setValue(res.data.testType.id);
                if (res.data.studentTestStatusTypeId == 6) {
                  if (res.data.testType.id == 34) {
                    this.registrationForm.get('collegeSpecId').setValue(res.data.collegeSpec.id);
                    this.collegeSpecSelect();
                    this.registrationForm.get('eduProgramId').setValue(res.data.eduProgram.id);
                    this.eduProgramSelect();
                    this.registrationForm.get('testLanguageId').setValue(res.data.testLanguage.id);
                  } else {
                    this.registrationForm.get('profileSubjectPairId').setValue(res.data.profileSubjectPair.id);
                    this.profileSubjectPairSelect();
                    this.registrationForm.get('testLanguageId').setValue(res.data.testLanguage.id);
                    this.varInfo();
                  }

                }
                this.agreementService.getSubjectPair(this.testPrefix, 33)
                  .subscribe((res: any) => {
                    this.subjectPairList = res.items;
                  }, (err: any) => {

                  });
                this.agreementService.getCollegeSpec(this.testPrefix, 34)
                  .subscribe((res: any) => {
                    this.collegeSpecList = res.items;
                  }, (err: any) => {

                  });

              }

              if (this.testTypeGroupId == 3) {
                this.registrationForm.get('entryPointId').setValue(res.data.entryPoint.id);
                this.registrationForm.get('testTypeId').setValue(res.data.testType.id);
                this.testTypeChange(res.data.testType.id, 0);
                this.registrationForm.get('employmentTypeId').setValue(res.data.employmentType.id);
                this.emplTypesChange(res.data.employmentType.id);
                this.registrationForm.get('eduTypeId').setValue(res.data.eduType.id);
                if (this.registrationForm.get('testTypeId').value == 40) {
                  this.getSpec();
                }
                this.registrationForm.get('specialityId').setValue(res.data.speciality.id);
                this.getSubj();
                this.registrationForm.get('subjectId').setValue(res.data.subject.id);
                if (this.registrationForm.get('testTypeId').value == 35 || this.registrationForm.get('testTypeId').value == 40) {
                  this.getTipoSubjectLang()
                }

                this.registrationForm.get('testLanguageId').setValue(res.data.testLanguage.id);
                this.varInfoNqt();
              }


            }

          }, (err: any) => {

          });

        this.testsInfo = res.data;

        if (this.testsInfo.studentTestStatusTypeId == 2) {
          this.router.navigate(['/test-rules']);
        } else if (this.testsInfo.studentTestStatusTypeId == 4) {
          this.mainTest.startTest({})
            .subscribe((res: any) => {
              this.router.navigate(['/test/quest/1/1']);
            }, (err: any) => {

            });
        } else if (this.testsInfo.studentTestStatusTypeId == 3) {
          this.mainTest.startTest({})
            .subscribe((res: any) => {
              this.router.navigate(['/test/quest/1/1']);
            }, (err: any) => {

            });
        } else if (this.testsInfo.studentTestStatusTypeId == 0) {
          this.router.navigate(['/agreement']);
        } else if (this.testsInfo.studentTestStatusTypeId == 1) {
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else if (this.testsInfo.studentTestStatusTypeId == 5) {
          // this.router.navigate([this.testsInfo.path.path]);
          if (res.path.pathTypeId == 2) {
            window.location.href = res.path.path + '?token=' + sessionStorage.getItem('token');
          } else {
            window.location.href = window.location.origin + '/' + res.path.path
          }
        } else if (this.testsInfo.studentTestStatusTypeId == 8) {
          // this.router.navigate([this.testsInfo.path.path]);
          if (res.path.pathTypeId == 2) {
            window.location.href = res.path.path + '?token=' + sessionStorage.getItem('token');
          } else {
            window.location.href = window.location.origin + '/' + res.path.path
          }
        } else if (this.testsInfo.studentTestStatusTypeId == 9) {
          // this.router.navigate([this.testsInfo.path.path]);
          if (res.path.pathTypeId == 2) {
            window.location.href = res.path.path + '?token=' + sessionStorage.getItem('token');
          } else {
            window.location.href = window.location.origin + '/' + res.path.path
          }
        } else if (this.testsInfo.studentTestStatusTypeId == 7) {
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else if (this.testsInfo.studentTestStatusTypeId == 11) {
          this.router.navigate(['/answer-map']);
        }
        // else if(this.testsInfo.studentTestStatusTypeId == 6){
        //   let data: {};
        //   this.mainTest.startAppeal(data).subscribe((res: any) => {
        //     // Переход на апелляцию по содержанию задания
        //     this.router.navigate(['/content-appeal/quest/1/1']);
        //   });
        // }





      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        }
      });
  }

  showEditSubjects() {
    if ((this.testsInfo.studentTestStatusTypeId == 0 || this.testsInfo.studentTestStatusTypeId == 1 || this.testsInfo.studentTestStatusTypeId == 2 || this.testsInfo.studentTestStatusTypeId == 3) && this.testsInfo.testType.changeTestParamsPageUrl.length !== 0) {
      return true;
    } else {
      return false;
    }
  }

  getSubj() {
    this.registrationForm.get('subjectId').setValue(0);
    this.agreementService.getTipoSubjects(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('employmentTypeId').value, this.registrationForm.get('eduTypeId').value, this.registrationForm.get('specialityId').value)
      .subscribe((res: any) => {
        this.subjectsList = res.items;
      }, (err: any) => {
      });
  }

  getSpec() {
    this.registrationForm.get('specialityId').setValue(0);
    this.registrationForm.get('subjectId').setValue(0);
    if (this.registrationForm.get('eduTypeId').value == 1) {
      this.registrationForm.get('specialityId').setValue(0);
      this.getSubj();
    }
    this.agreementService.getSpec(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('employmentTypeId').value, this.registrationForm.get('eduTypeId').value)
      .subscribe((res: any) => {
        this.specList = res.items;
      }, (err: any) => {

      });
  }

  getSubjectLang(subjectId: number) {
    this.agreementService.getSubjectLang(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('employmentTypeId').value, subjectId)
      .subscribe((res: any) => {
        this.listOfLanguages = res.items;
      });
  }

  getTipoSubjectLang() {
    this.registrationForm.get('testLanguageId').setValue(0);
    this.varData = [];
    if (this.registrationForm.get('testTypeId').value == 40) {
      this.agreementService.getTipoSubjectLang(this.testPrefix, this.registrationForm.get('testTypeId').value, this.registrationForm.get('employmentTypeId').value, this.registrationForm.get('subjectId').value, this.registrationForm.get('specialityId').value, this.registrationForm.get('eduTypeId').value)
        .subscribe((res: any) => {
          this.listOfLanguages = res.items;
        }
        );
    } else {
      this.getSubjectLang(this.registrationForm.get('subjectId').value);
    }

  }

  eduLangInit() {

    if (this.testTypeGroupId == 1) {
      this.agreementService.getForeignLanguage(this.testTypeValue, this.educationLanguageId, this.testPrefix)
        .subscribe((res: any) => {
          this.foreignLanguages = res.items;
        });
      this.agreementService.tgoLanguage(this.testTypeValue, this.educationLanguageId, this.testPrefix)
        .subscribe((res: any) => {
          this.tgoLanguages = res.items;
        });
      this.agreementService.eduProgram(this.testTypeValue, this.educationLanguageId, this.testPrefix)
        .subscribe((res: any) => {
          this.eduPrograms = res.items;
        });
    }

  }

  educationLanguageSelect(value: any) {
    this.educationLanguageId = value;

    this.showForeignLanguage = true;
    if (this.educationLanguageId == 3) {
      this.showForeignLanguage = false;
    }
    this.eduLangInit();

  }

  private wrongData(): void {
    // Если указанные данные неверны
    this.loading = true;
    this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
  }

  correctData() {
    this.agreementService.correctData(this.registrationForm.value, this.testPrefix)
      .subscribe((res: any) => {
        if (res.errorCode === 0) {
          this.loading = true;
          if (res.path.pathTypeId == 2) {
            window.location.href = res.path.path + '?token=' + sessionStorage.getItem('token');
          } else {
            window.location.href = window.location.origin + '/' + res.path.path
          }
        }
      }, (err: any) => {
        if (err.status == 401) {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });

  }

  cancel() {

    this.mainTest.startTest({})
      .subscribe((res: any) => {
        this.router.navigate(['/test/quest/1/1']);
      }, (err: any) => {

      });

  }


  editDataUrl() {
    let url = 'http://localhost:4200/edit-subjects/';
    // console.log(url);
    return this.testsInfo.testType.changeTestParamsPageUrl + '?token=' + sessionStorage.getItem('token') + '&url=' + window.location.href;
  }
}
