import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  constructor() { }


  public isMobile(width: number): boolean {
    if (width < 769) {
      return true;
    } else {
      return false;
    }
  }
}
