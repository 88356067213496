import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import { listener } from '../../../../assets/calculator';
import { atan2, chain, derivative, e, evaluate, log, pi, pow, round, sqrt } from 'mathjs'
import {Decimal} from 'decimal.js';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent {

  @Output() onClose = new EventEmitter();

  firstOperand: any = '0';
  secondOperand: any = '0';
  operator: string = '';
  showOperator = false;
  showSecondOperand = false;

  


  constructor( ) {
  }

  zero(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '0';
          break;
        default:
          this.firstOperand = this.firstOperand + '0';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '0';
          break;
        default:
          this.secondOperand = this.secondOperand + '0';
          break;
      }
    }
  }

  one(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '1';
          break;
        default:
          this.firstOperand = this.firstOperand + '1';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '1';
          break;
        default:
          this.secondOperand = this.secondOperand + '1';
          break;
      }
    }
  }

  two(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '2';
          break;
        default:
          this.firstOperand = this.firstOperand + '2';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '2';
          break;
        default:
          this.secondOperand = this.secondOperand + '2';
          break;
      }
    }
  }

  three(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '3';
          break;
        default:
          this.firstOperand = this.firstOperand + '3';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '3';
          break;
        default:
          this.secondOperand = this.secondOperand + '3';
          break;
      }
    }
  }

  four(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '4';
          break;
        default:
          this.firstOperand = this.firstOperand + '4';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '4';
          break;
        default:
          this.secondOperand = this.secondOperand + '4';
          break;
      }
    }
  }

  five(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '5';
          break;
        default:
          this.firstOperand = this.firstOperand + '5';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '5';
          break;
        default:
          this.secondOperand = this.secondOperand + '5';
          break;
      }
    }
  }

  six(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '6';
          break;
        default:
          this.firstOperand = this.firstOperand + '6';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '6';
          break;
        default:
          this.secondOperand = this.secondOperand + '6';
          break;
      }
    }
  }

  seven(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '7';
          break;
        default:
          this.firstOperand = this.firstOperand + '7';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '7';
          break;
        default:
          this.secondOperand = this.secondOperand + '7';
          break;
      }
    }
  }

  eight(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '8';
          break;
        default:
          this.firstOperand = this.firstOperand + '8';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '8';
          break;
        default:
          this.secondOperand = this.secondOperand + '8';
          break;
      }
    }
  }

  nine(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '9';
          break;
        default:
          this.firstOperand = this.firstOperand + '9';
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '9';
          break;
        default:
          this.secondOperand = this.secondOperand + '9';
          break;
      }
    }
  }

  comma(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        default:
          this.firstOperand = this.firstOperand + '.';
          break;
      }
    }else{
      switch (this.secondOperand) {
        default:
          this.secondOperand = this.secondOperand + '.';
          break;
      }
    }
  }

  deleteElement(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        default:
          this.firstOperand = this.firstOperand + '';
          this.firstOperand = this.firstOperand.substring(0, this.firstOperand.length - 1);
          break;
      }
    }else{
      switch (this.secondOperand) {
        default:
          this.secondOperand = this.secondOperand + '';
          this.secondOperand = this.secondOperand.substring(0, this.secondOperand.length - 1);
          break;
      }
    }
  }

  oneSplitX(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '0';
          break;
        default:
          this.firstOperand = 1 / this.firstOperand;
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '0';
          break;
        default:
          this.secondOperand = 1 / this.secondOperand;
          break;
      }
    }
  }

  percent(){
    this.secondOperand = (this.firstOperand * this.secondOperand) / 100;
  }

  clearLastArgument(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        default:
          this.firstOperand = '0';
          break;
      }
    }else{
      switch (this.secondOperand) {
        default:
          this.secondOperand = '0';
          break;
      }
    }
  }

  changeSign(){
    if(Math.sign(this.firstOperand) == 1){
      if(this.operator == ''){
        switch (this.firstOperand) {
          default:
            this.firstOperand = '-' + this.firstOperand;
            break;
        }
      }
    }else{
      if(this.operator == ''){
        switch (this.firstOperand) {
          default:
            this.firstOperand = this.firstOperand.substring(1);
            break;
        }
      }
    }

    if(Math.sign(this.secondOperand) == 1){
      if(!!this.operator){
        switch (this.secondOperand) {
          default:
            this.secondOperand = '-' + this.secondOperand;
            break;
        }
      }
    }else{
      if(!!this.operator){
        switch (this.secondOperand) {
          default:
            this.secondOperand = this.secondOperand.substring(1);
            break;
        }
      }
    }
  }

  multiply(){
    if(!!this.operator){
      this.equals();
    }
    this.showOperator = true;
    this.operator = '*';
    this.showSecondOperand = true;
  }

  split(){
    if(!!this.operator){
      this.equals();
    }
    this.showOperator = true;
    this.operator = '/';
    this.showSecondOperand = true;
  }

  squareRoot(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '0';
          break;
        default:
          this.firstOperand = Math.sqrt(this.firstOperand);
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '0';
          break;
        default:
          this.secondOperand = Math.sqrt(this.secondOperand);
          break;
      }
    }
  }

  square(){
    if(this.operator == ''){
      switch (this.firstOperand) {
        case '0':
          this.firstOperand = '0';
          break;
        default:
          this.firstOperand = Math.pow(this.firstOperand, 2);
          break;
      }
    }else{
      switch (this.secondOperand) {
        case '0':
          this.secondOperand = '0';
          break;
        default:
          this.secondOperand = Math.pow(this.secondOperand, 2);
          break;
      }
    }
  }

  plus(){
    if(!!this.operator){
      this.equals();
    }
    this.showOperator = true;
    this.operator = '+';
    this.showSecondOperand = true;
  }

  minus(){
    if(!!this.operator){
      this.equals();
    }
    this.showOperator = true;
    this.operator = '-';
    this.showSecondOperand = true;
  }

  equals(){
    // let a = new Decimal(this.firstOperand);
    // let c = a.minus(this.secondOperand);
    // console.log(c.toFixed());
    let a: any;
    let b: any;
    let c: any;
    switch (this.operator) {
      case '+':
        // this.firstOperand = Math.floor((+this.firstOperand + +this.secondOperand) * 100000) / 100000;
        a = new Decimal(+this.firstOperand);
        b = new Decimal(+this.secondOperand);
        c = a.plus(b);
        this.firstOperand = c.toFixed();
        this.secondOperand = '0';
        this.showSecondOperand = false;
        this.showOperator = false;
        this.operator = '';
        break;
      case '-':
        // this.firstOperand = Math.floor((+this.firstOperand - +this.secondOperand) * 100000) / 100000;
        a = new Decimal(+this.firstOperand);
        b = new Decimal(+this.secondOperand);
        c = a.minus(b);
        this.firstOperand = c.toFixed();
        this.secondOperand = '0';
        this.showSecondOperand = false;
        this.showOperator = false;
        this.operator = '';
        break;
      case '*':
        // this.firstOperand = Math.floor((+this.firstOperand * +this.secondOperand) * 100000) / 100000;
        a = new Decimal(+this.firstOperand);
        b = new Decimal(+this.secondOperand);
        c = a.mul(b);
        this.firstOperand = c.toFixed();
        this.secondOperand = '0';
        this.showSecondOperand = false;
        this.showOperator = false;
        this.operator = '';
        break;
      case '/':
        // this.firstOperand = Math.floor((+this.firstOperand / +this.secondOperand) * 100000) / 100000;
        a = new Decimal(+this.firstOperand);
        b = new Decimal(+this.secondOperand);
        c = a.div(b);
        this.firstOperand = c.toFixed();
        this.secondOperand = '0';
        this.showSecondOperand = false;
        this.showOperator = false;
        this.operator = '';
        break;
    }
    
    // console.log(0.3 - 0.1);
  }

  clearFull(){
    this.firstOperand = '0';
    this.secondOperand = '0';
    this.operator = '';
    this.showOperator = false;
    this.showSecondOperand = false;
  }

  // @ViewChild('output', {static: false}) outputRef: ElementRef;
  // signs = [
  //   '√', 'CE', '', '/',
  //   '7', '8', '9', '*',
  //   '4', '5', '6', '-',
  //   '1', '2', '3', '+',
  //   '00', '0', '.', '='
  // ];

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event): void {
    if (event && event.key && ((event.key).match(/[0-9%\/*\-+.\(\)=]|Backspace|Enter/))) {
      this.onBtbClick(event.key);
    }
  }

  onBtbClick(key): void {
    // const output = this.outputRef.nativeElement;
    if (key && key.length > 0) {
      switch (key) {
        // case '=':
        case 'Enter':
          this.equals();
          break;
        case 'C':
          this.clearFull();
          break;
        case 'Backspace':
          this.deleteElement();
          break;
        case '*':
          this.multiply();
          break;
        case '+':
          this.plus();
          break;
        case '-':
          this.minus();
          break;
        case '/':
          this.split();
          break;
        case '.':
          this.comma();
          break;
        case '1':
          this.one();
          break;
        case '2':
          this.two();
          break;
        case '3':
          this.three();
          break;
        case '4':
          this.four();
          break;
        case '5':
          this.five();
          break;
        case '6':
          this.six();
          break;
        case '7':
          this.seven();
          break;
        case '8':
          this.eight();
          break;
        case '9':
          this.nine();
          break;
        case '0':
          this.zero();
          break;
        default:
          // output.textContent += key;
          break;
      }
    }
    
  }

  removeCalculator(){
    removeEventListener("keydown", listener, false);
    this.onClose.emit(null);
  }



}
