import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-soli',
  templateUrl: './soli.component.html',
  styleUrls: ['./soli.component.scss']
})
export class SoliComponent implements OnInit {

  @Input() isMobile: boolean;
  @Output() onClose = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  removeMapModal(){
    this.onClose.emit(null);
  }
}
