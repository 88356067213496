import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class apiConfig {
  backend: any;
  apiRoutes: any;
  backendChecker: any;
  backendCustom: any;

  constructor() {
    if (isDevMode()) {
      this.backend = 'https://probtest.debug.testcenter.kz/tester/core/api/v1'; // Путь для localhost
      this.backendChecker = 'http://192.168.200.13:28080/online_test/computer-registration/api/v1';
      this.backendCustom = 'https://probtest.debug.testcenter.kz'; // Путь для localhost
      // this.backend = 'https://apprtest.testcenter.kz/online_test_phd/tester/api'; // Путь для localhost
      // this.backendChecker = 'http://192.168.200.13:28080/online_test/computer-registration/api/v1';
    }
    if (!isDevMode()) {
      this.backend = 'tester/core/api/v1'; // Путь для боевого
      this.backendChecker = 'online_test/computer-registration/api/v1';
      this.backendCustom = './'; // Путь для localhost
    }

    this.apiRoutes = { // ссылки для запросов
      auth: this.backend + '/login',
      testList: this.backend + '/login/test-type/items',
      eduLanguage: this.backend + '/reference/test-type/items/',
      testInfo: this.backend + '/test-info',
      studentInfo: this.backend + '/student-info',
      listOfItems: this.backend + '/subject/items',
      rules: this.backend + '/rules',
      timeLeft: this.backend + '/time-left',
      contentAppeal: this.backend + '/appeal/start',
      contentAppealFinish: this.backend + '/appeal/finish',
      startTest: this.backend + '/test/start',
      finishTest: this.backend + '/test/finish',
      startPause: this.backend + '/test/pause/start',
      finishPause: this.backend + '/test/pause/finish',
      testResults: this.backend + '/test/page-image'
    }

  }
}
