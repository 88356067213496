import { Component, OnInit, isDevMode } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { MainTestService } from 'src/app/services/mainTest.service';

@Component({
  selector: 'app-test-rules',
  templateUrl: './test-rules.component.html',
  styleUrls: ['./test-rules.component.css']
})
export class TestRulesComponent implements OnInit {
  showIFrame = true;
  showIFrameKZ = true;
  iFrameRoutePrefix = './';
  iFrameRoute: any;
  iFrameRouteKZ: any;

  testsInfo: any = null;
  loading = false;
  typesTest: any = null;
  form: FormGroup;
  agree = false;
  errWindow = false;
  serverMessage: any;
  currentLang: string;

  constructor(private router: Router,
              private mainTest: MainTestService,
              private _notifications: NotificationsService,
              public translate: TranslateService,
              public sanitizer: DomSanitizer, 
              public translateService: TranslateService) {
                if (isDevMode()) {
                  this.iFrameRoutePrefix = 'https://test.debug.testcenter.kz'; // Путь для localhost
                }
                if (!isDevMode()) {
                  this.iFrameRoutePrefix = './'; // Путь для боевого
                }
  }

  ngOnInit() {
    sessionStorage.setItem('showlang', '1');

    this.loading = true;
    this.getTestInfo();
  }

  // Получение информации о тесте
  private getTestInfo(): void {
    this.mainTest.testInfo()
    .subscribe((res: any) => {
      this.testsInfo = res.data;
      this.setRules();
      if(this.testsInfo.studentTestStatusTypeId == 4){
        this.startTest();
      }else if(this.testsInfo.studentTestStatusTypeId == 3){
        this.startTest();
      }else if(this.testsInfo.studentTestStatusTypeId == 0){
        this.router.navigate(['/agreement']);
      }else if(this.testsInfo.studentTestStatusTypeId == 1){
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }else if(this.testsInfo.studentTestStatusTypeId == 5){
        if(res.path.pathTypeId == 2){
          window.location.href = res.path.path
        }else{
          window.location.href = window.location.origin + '/' + res.path.path
        }
      }else if(this.testsInfo.studentTestStatusTypeId == 8){
        if(res.path.pathTypeId == 2){
          window.location.href = res.path.path
        }else{
          window.location.href = window.location.origin + '/' + res.path.path
        }
      }else if(this.testsInfo.studentTestStatusTypeId == 9){
        if(res.path.pathTypeId == 2){
          window.location.href = res.path.path
        }else{
          window.location.href = window.location.origin + '/' + res.path.path
        }
      }else if(this.testsInfo.studentTestStatusTypeId == 7){
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }else if(this.testsInfo.studentTestStatusTypeId == 6){
        this.router.navigate(['/content-appeal/quest/1/1']);
      }

    }, (err: any) => {
      if(err.status == 401){
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }
    });
  }

  // Получение всех типов тестов
  private getAllTestTypes(): any {
    this.mainTest.getAll()
      .subscribe((res: any) => {
        if(res.items){
          this.typesTest = res.items;
          this.setRules();
          this.loading = false;
        }
      }, (err: any) => {
        this.errWindow = true;
        this.serverMessage = err.error.errorMessage;
      });
  }

  // Set rules
  private setRules(): void {
    this.mainTest.getRules()
      .subscribe((res: any) => {

        if(res.items) {
          if(this.currentLang = 'kz'){
            this.showIFrameKZ = true;
            this.showIFrame = false;
            
          }
          if(this.currentLang = 'ru'){
            this.showIFrameKZ = false;
            this.showIFrame = true;
            
          }
          this.iFrameRouteKZ = this.sanitizer.bypassSecurityTrustResourceUrl(this.iFrameRoutePrefix + res.items.kz);
          this.iFrameRoute = this.sanitizer.bypassSecurityTrustResourceUrl(this.iFrameRoutePrefix + res.items.ru);
          console.log(this.iFrameRouteKZ);
            this.loading = false;
        }

      });
  }

// Начало тестирования
  public startTest(): void {
      this.mainTest.startTest({})
      .subscribe((res: any) =>
        {
          if(res.path.pathTypeId == 2){
            window.location.href = res.path.path
          }else{
            window.location.href = window.location.origin + '/' + res.path.path
          }
        }, (err: any) => {
          if(err.status == 401){
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
            this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
          }else{
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          }
        });
   
  }
//  Принятие правил тестирования
   public agreeRules(): void {
    this.mainTest.agreeRules({})
      .subscribe((res: any) =>
        {
          if(res.errorCode == 0) {
            this.startTest();
            sessionStorage.setItem('showlang', '0');
          }
        }, (err: any) => {
          if(err.status == 401){
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
            this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
            sessionStorage.setItem('showlang', '1');
          }else{
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          }
        }
      );
  }
}
