import { Component, OnInit, Input, SimpleChanges, OnChanges, HostListener } from '@angular/core';
import { MainTestService } from 'src/app/services/mainTest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router } from '@angular/router';
import '@ckeditor/ckeditor5-build-classic/build/translations/de';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { DisplayService } from 'src/app/services/display.service';

@Component({
  selector: 'app-quest-block',
  templateUrl: './quest-block.component.html',
  styleUrls: ['./quest-block.component.css']
})
export class QuestBlockComponent implements OnInit {
  @Input() currentPredmetId: number;
  @Input() currentTab: number;
  @Input() contextValue: any;
  @Input() iFrameQuest: any; // Варианты ответов
  @Input() checkStatusArr: any; // Проверка выбранного варианта ответа
  @Input() answerLetter: any; // Массив букв вариантов ответов
  @Input() questionItems: any; // Вопросы предмета
  @Input() order: any;
  @Input() contextQuestion: any;
  @Input() question: any;
  @Input() audioValue: any;
  @Input() audioUrl: any;
  @Input() currentTime: any;
  @Input() reportList: any;
  @Input() answerType: any;
  @Input() esseContent: any;
  @Input() answerId: any;
  @Input() showBarcode: any;
  @Input() matchingAnswers: any;
  @Input() isMobile: boolean;


  //selected: any[] = [];
  configDropdown = {
    displayKey: 'content', //if objects array passed which key to be displayed defaults to description
    search: false, //true/false for the search functionlity defaults to false,
    //height: 'auto' //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    //placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
    //customComparator: ()=>{} // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: 5, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    //moreText: 'more' // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    //noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    //searchPlaceholder:'Search' // label thats displayed in search input
  }

  loading = false;
  textAnswer: any;
  save = false;
  public Editor = ClassicEditor;
  public config = {
    // builtinPlugins: { Autosave },
    language: 'ru',
    toolbar: {
      items: [
        'bold', 'italic', '|',
        'bulletedList', 'numberedList', '|',
        'insertTable', '|',
        'undo', 'redo'
      ],
      shouldNotGroupWhenFull: true
    },

  };
  // contextQuestion: any; // Вопрос с несколькими вариантами ответов
  // question: any; // Вопрос с 1 вариантом ответа
  // audioValue = false; // Наличие аудиофайла
  // audioUrl: any;

  constructor(private mainTest: MainTestService, public sanitizer: DomSanitizer,
    private _notifications: NotificationsService,
    private router: Router,
    public translate: TranslateService,
    // private displayService:DisplayService
  ) { }

  ngOnInit() {
    this.textAnswer = { textAnswer: '' };
    // this.isMobile = this.displayService.isMobile(+window.innerWidth);
  }

  // @HostListener('window:resize', ['$event'])
  // onWindowResize() {
  //   this.isMobile = this.displayService.isMobile(+window.innerWidth);
  // }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.question && changes.question.currentValue) {
  //     if (this.answerType == 5) {
  //       this.matchingAnswers.forEach((answer: any, index: any) => {
  //         if (answer.isSelected) {
  //           answer.answerVariants.forEach((variant: any) => {
  //             if (variant.isSelected) {
  //               //console.log(`Variant with index ${index} and variant order ${variant.order}`)
  //               this.selected[index] = variant.order
  //             }
  //           })
  //         } else {
  //           this.selected[index] = "0"
  //         }

  //       });
  //     }

  //   }
  // }

  // Логика выбора ответов 1 варианта ответов

  editorChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.textAnswer = { textAnswer: data };
    sessionStorage.setItem('esseText', this.textAnswer);
  }

  esseVersion() {
    let version: any = sessionStorage.getItem('esseVersion');
    sessionStorage.setItem('esseVersion', version + 1);
  }

  saveEsse() {
    this.loading = true;
    this.mainTest.selectedAnswer(this.currentPredmetId, this.currentTab, 0, this.textAnswer)
      .subscribe((res: any) => {
        this.save = false;
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Эссе сохранено', '', NotificationType.Success, 5000) : this._notifications.create('Эссе сақталды', '', NotificationType.Success, 5000);
      }, (err: any) => {
        if (err.status == 0) {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'проблемы с сетью, для сохранения ответа нажмите на кнопку "Сохранить" повторно', NotificationType.Error, 5000) : this._notifications.create('Қате', 'желіде ақаулар бар, жауаптың сақталуы үшін қайтадан "Сақтау" батырмасына басыңыз', NotificationType.Error, 5000);
          this.loading = false;
        }
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        this.loading = false;
      });

  }

  // saveEsseTosessionStorage({ editor }: ChangeEvent){
  //   const data = editor.getData();
  //   this.textAnswer = { textAnswer: data};

  // }


  // selectChange(e: number, option: any) {
  //   let obj = {
  //     selectedAnswerVariantOrder: +option
  //   };
  //   this.loading = true;
  //   this.mainTest.selectedAnswer(this.currentPredmetId, this.currentTab, +e, obj)
  //     .subscribe((res: any) => {
  //       this.loading = false;
  //       this.checkStatusArr.forEach((element, index) => {
  //         this.checkStatusArr[index] = res.data.selectedAnswers.includes(this.order[index]);
  //       });
  //       if (res.data.selectedAnswerCount === this.matchingAnswers.length) {
  //         this.questionItems[+this.currentTab - 1]['selectedAnswerCount'] = res.data.selectedAnswerCount;
  //       } else {
  //         this.questionItems[+this.currentTab - 1]['selectedAnswerCount'] = 0;
  //       }
  //     }, (err: any) => {
  //       this.loading = false;
  //       if (err.status == 401) {
  //         this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
  //         this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
  //       } else {
  //         this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
  //       }
  //     });
  // }

  selectedChange(e:any){
    let obj = {
      selectedAnswerVariantOrder: +e.answerOrder
    };
    this.loading = true;
    this.mainTest.selectedAnswer(this.currentPredmetId, this.currentTab, +e.questionOrder, obj)
      .subscribe((res: any) => {
        this.loading = false;
        this.checkStatusArr.forEach((element, index) => {
          this.checkStatusArr[index] = res.data.selectedAnswers.includes(this.order[index]);
        });
        if (res.data.selectedAnswerCount === this.matchingAnswers.length) {
          this.questionItems[+this.currentTab - 1]['selectedAnswerCount'] = res.data.selectedAnswerCount;
        } else {
          this.questionItems[+this.currentTab - 1]['selectedAnswerCount'] = 0;
        }
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
  }

  answerSelect(i: number): void {
    let obj = {
      isSelected: !this.checkStatusArr[i]
    };
    console.log(this.checkStatusArr);
    this.loading = true;
    this.mainTest.selectedAnswer(this.currentPredmetId, this.currentTab, this.order[i], obj)
      .subscribe((res: any) => {
        this.loading = false;
        this.checkStatusArr.forEach((element, index) => {
          // console.log(this.order[index]);
          // console.log(res.data);
          this.checkStatusArr[index] = res.data.selectedAnswers.includes(this.order[index]);
        });
        this.questionItems[+this.currentTab - 1]['selectedAnswers'] = res.data.selectedAnswerChars;
        this.questionItems[+this.currentTab - 1]['selectedAnswerCount'] = res.data.selectedAnswerCount;
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
  }

}
