import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MainTestService } from 'src/app/services/mainTest.service';

@Component({
  selector: 'app-minimap',
  templateUrl: './minimap.component.html',
  styleUrls: ['./minimap.component.css']
})
export class MinimapComponent implements OnInit {

  @Input() isMobile: boolean;
  @Output() onCloseMap = new EventEmitter();

  items = [];
  loading = false;
  canAppealCheck: boolean;
  testsInfo: any;

  constructor(public translate: TranslateService, private mainTest: MainTestService) { }

  ngOnInit() {
    this.loading = true;
    this.items = [];
    this.getTestInfo();
    this.loadMapModal();
  }

  removeMapModal(){
    this.onCloseMap.emit(null);
  }

  getTestInfo(){
    this.mainTest.testInfo()
      .subscribe((res: any) => {
        this.testsInfo = res.data;
        this.canAppealCheck = res.data.testType.canAppeal;
      },(err: any) => {
        this.loading = false;
      });
  }

  // Модальное окно с картой ответов
  loadMapModal(): void {
    // Получение списка предметов
    this.mainTest.listOfItems()
      .subscribe((res: any) => {
        this.items = [];
        res.data.subjects.forEach(element => {
          // if(element.showInCard == true){
            let obj = {
              element: element,
              answers: []
            }
            this.items.push(obj);  
          // }
          
        });

        this.getAnswer();
      },(err: any) => {
        this.loading = false;
        // this.errWindow = true;
        // this.serverMessage = err.error.errorMessage;
      });

  }

  private getAnswer(): void {
    this.items.forEach((element, i) => {
      delete this.items[i]['answers'];
      this.items[i].answers = [];
      this.loading = true;
        this.mainTest.questionAnsweredItems(element.element.order)
        .subscribe((res: any) => {
          this.items[i]['answers'].push(res.data.questions);
          this.loading = false;
        }, (err: any) => {
          this.loading = false;
          // this.errWindow = true;
          // this.serverMessage = err.error.errorMessage;
        });
    });
  }

}
