import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { apiConfig } from '../config/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  constructor(private http: HttpClient, private tokenService: TokenService,
    private config: apiConfig) { }

  correctData(data: any, prefix: any): any {
    return this.http.put(this.config.backendCustom + '/' + prefix + '/test-info', data, this.tokenService.getHeaderAuth());
  }
  studentDataChange(studentData: any, prefix: any): any {
    return this.http.put(this.config.backendCustom + '/' + prefix + '/student-info', studentData, this.tokenService.getHeaderAuth());
  }
  eduLanguage(testTypeId: number, prefix: any): any {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/edu-language/items', this.tokenService.getHeaderAuth());
  }
  getForeignLanguage(testTypeId: number, languageId: number, prefix: any): any {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/edu-language/items/' + languageId + '/foreign-language/items', this.tokenService.getHeaderAuth());
  }
  tgoLanguage(testTypeId: number, languageId: number, prefix: any): any {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/edu-language/items/' + languageId + '/tgo-language/items', this.tokenService.getHeaderAuth());
  }
  eduProgram(testTypeId: number, languageId: number, prefix: any): any {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/edu-language/items/' + languageId + '/edu-program/items', this.tokenService.getHeaderAuth());
  }

  getSubjectPair(prefix: any, testTypeId: any) {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/profile-subject-pair/items', this.tokenService.getHeaderAuth());
  }

  getEntTestLang(prefix: any, testTypeId: any, profileSubjectPairId: any) {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/profile-subject-pair/items/' + profileSubjectPairId + '/test-language/items', this.tokenService.getHeaderAuth());
  }

  getCollegeSpec(prefix: any, testTypeId: any) {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/college-spec/items', this.tokenService.getHeaderAuth());
  }

  getEntCollegeEduProgram(prefix: any, testTypeId: any, collegeSpecId: any) {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/college-spec/items/' + collegeSpecId + '/edu-program/items', this.tokenService.getHeaderAuth());
  }

  getEntCollegeLangList(prefix: any, testTypeId: any, eduProgramId: any) {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/edu-program/items/' + eduProgramId + '/test-language/items', this.tokenService.getHeaderAuth());
  }

  getTable(prefix: any, testTypeId: any, profileSubjectPairId: any, testLangId: any) {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/profile-subject-pair/items/' + profileSubjectPairId + '/test-language/items/' + testLangId + '/var-info', this.tokenService.getHeaderAuth());
  }

  getTableNqt(prefix: any, testTypeId: any, emplTypeId: number, subjectId: any, testLangId: any) {
    return this.http.get(`${this.config.backendCustom}/${prefix}/reference/test-type/items/${testTypeId}/employment-type/items/${emplTypeId}/subject/items/${subjectId}/lang/items/${testLangId}/var-info`, this.tokenService.getHeaderAuth());
  }

  getTableTipoNqt(prefix: any, testTypeId: any, emplTypeId: number, eduTypeId: number, specialityId: number, subjectId: any, testLangId: any) {
    return this.http.get(`${this.config.backendCustom}/${prefix}/reference/test-type/items/${testTypeId}/employment-type/items/${emplTypeId}/edu-type/items/${eduTypeId}/speciality/items/${specialityId}/subject/items/${subjectId}/lang/items/${testLangId}/var-info`, this.tokenService.getHeaderAuth());
  }

  getSubjects(prefix: any, testTypeId: any, emplTypeId: number) {

    return this.http.get(`${this.config.backendCustom}/${prefix}/reference/test-type/items/${testTypeId}/employment-type/items/${emplTypeId}/subject/items`, this.tokenService.getHeaderAuth());
  }

  getSubjectLang(prefix: any, testTypeId: any, employmentTypeId: number, subjectGroupId: any) {
    return this.http.get(`${this.config.backendCustom}/${prefix}/reference/test-type/items/${testTypeId}/employment-type/items/${employmentTypeId}/subject/items/${subjectGroupId}/lang/items`, this.tokenService.getHeaderAuth());
  }

  getTipoSubjectLang(prefix: any, testTypeId: any, employmentTypeId: number, subjectId: any, specialityId: any, eduTypeId: any) {
    return this.http.get(`${this.config.backendCustom}/${prefix}/reference/test-type/items/${testTypeId}/employment-type/items/${employmentTypeId}/edu-type/items/${eduTypeId}/speciality/items/${specialityId}/subject/items/${subjectId}/lang/items`, this.tokenService.getHeaderAuth());
  }

  getSubjectLangs(prefix: any, testTypeId: any) {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/lang/items', this.tokenService.getHeaderAuth());
  }

  getPSubjects(prefix: any, testTypeId: any, employmentTypeId: number) {

    return this.http.get(`${this.config.backendCustom}/${prefix}/reference/test-type/items/${testTypeId}/employment-type/items/${employmentTypeId}/edu-type/items`, this.tokenService.getHeaderAuth());
  }

  getSpec(prefix: any, testTypeId: any, employmentTypeId: number, eduTypeId: any) {

    return this.http.get(`${this.config.backendCustom}/${prefix}/reference/test-type/items/${testTypeId}/employment-type/items/${employmentTypeId}/edu-type/items/${eduTypeId}/speciality/items`, this.tokenService.getHeaderAuth());
  }

  getTipoSubjects(prefix: any, testTypeId: any, employmentTypeId: number, eduTypeId: any, specialityId: any) {
    return this.http.get(`${this.config.backendCustom}/${prefix}/reference/test-type/items/${testTypeId}/employment-type/items/${employmentTypeId}/edu-type/items/${eduTypeId}/speciality/items/${specialityId}/subject/items`, this.tokenService.getHeaderAuth());
  }

  getEmploymentTypes(prefix: any, testTypeId: any) {
    return this.http.get(this.config.backendCustom + '/' + prefix + '/reference/test-type/items/' + testTypeId + '/employment-type/items', this.tokenService.getHeaderAuth());
  }
}
