import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {

  showlang = '1';
  isMob = false;

  constructor(public translate: TranslateService, private route: ActivatedRoute,){
    // sessionStorage.setItem('date', this.route.snapshot.queryParamMap.get('kiosk'));
    
    const isMob = sessionStorage.getItem("isMobile")
    if(isMob) {
      this.isMob = true
    }

  }
  ngOnInit(): void {
    //console.log(window.location);
    this.translate.addLangs(['ru', 'kz']);
    this.translate.setDefaultLang('kz');

    const browserLang = this.translate.getBrowserLang();
    console.log(sessionStorage.getItem('lang'));
    if(sessionStorage.getItem('lang') == undefined || sessionStorage.getItem('lang') == null){
      this.translate.use('kz');
      sessionStorage.setItem('lang', 'kz');
    }else{
      this.translate.use(sessionStorage.getItem('lang'));
    }
    this.showlang = sessionStorage.getItem('showlang');
  }
  title = 'online-test';

  changeLang(val: any){
    if(val == 'kz'){
      sessionStorage.setItem('lang', 'kz');
      this.translate.use('kz');
    }else{
      sessionStorage.setItem('lang', 'ru');
      this.translate.use('ru');
    }
  }

  
}
