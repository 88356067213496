import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild, Input} from '@angular/core';

@Component({
  selector: 'app-mendeleev',
  templateUrl: './mendeleev.component.html',
  styleUrls: ['./mendeleev.component.css']
})
export class MendeleevComponent implements OnInit {

  @Input() isMobile: boolean;
  @Output() onClose = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  removeMapModal(){
    this.onClose.emit(null);
  }
}
